/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type { MaybeRef } from 'vue'
import { computed, toValue } from 'vue'
import type { Route } from '~/src/Application/Shared/Router/RouterInterface'
import type Organisation from '~/src/Domain/Organisation/Organisation'
import useNavigation from '~/src/UserInterface/App/composables/Navigation/useNavigation'

export default function useOrganisationNavigation(organisation: MaybeRef<Organisation>) {
  const { getLocalePath, navigateTo, navigateToLocalePath, uuidEncoder } = useNavigation()

  const params = computed(() => ({
    organisationId: uuidEncoder.encode(toValue(organisation)['@id'].toId()),
    organisationSlug: toValue(organisation).getSlug().toString(),
  }))

  const getOrganisationLocalePath = (route: Route) => {
    if (typeof route === 'string') {
      return getLocalePath({
        name: route,
        params: params.value,
      })
    }

    const routeParams = route.params ?? {}
    for (const param of ['engagementId', 'engagementSlug', 'phaseId', 'phaseSlug']) {
      if (param in routeParams) {
        delete routeParams[param]
      }
    }

    route.params = {
      ...routeParams,
      ...params.value,
    }

    return getLocalePath(route)
  }

  const organisationSettingsGeneralLocalePath = getOrganisationLocalePath(
    'settings-organisation-General',
  )
  const organisationSettingsEngagementsLocalePath = getOrganisationLocalePath(
    'settings-organisation-Engagement',
  )
  const organisationSettingsEngagementAddLocalePath = getOrganisationLocalePath(
    'settings-organisation-EngagementAdd',
  )

  const navigateToOrganisationSettingsGeneralPage = async (): Promise<void> => {
    await navigateTo(organisationSettingsGeneralLocalePath)
  }

  const navigateToOrganisationSettingsEngagementPage = async (): Promise<void> => {
    await navigateTo(organisationSettingsEngagementsLocalePath)
  }

  const navigateToOrganisationSettingsEngagementAddPage = async (): Promise<void> => {
    await navigateTo(organisationSettingsEngagementAddLocalePath)
  }

  const navigateToOrganisationSettingsEngagementAddSuccessPage = async (token: string) => {
    await navigateToLocalePath({
      name: 'settings-organisation-EngagementAddSuccess',
      params: {
        token,
      },
    })
  }

  return {
    getLocalePath,
    navigateToOrganisationSettingsGeneralPage,
    navigateToOrganisationSettingsEngagementPage,
    navigateToOrganisationSettingsEngagementAddPage,
    navigateToOrganisationSettingsEngagementAddSuccessPage,
    organisationSettingsGeneralLocalePath,
    organisationSettingsEngagementsLocalePath,
    organisationSettingsEngagementAddLocalePath,
  }
}
